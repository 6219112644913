import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation])

export default class extends Controller {
  static targets = ['next', 'prev', 'sliderContainer']

  connect() {
    new Swiper(this.sliderContainerTarget, {
      loop: true,
      slidesPerView: 1,
      speed: 600,
      autoplay: {
        delay: 6000,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
    })
  }
}
