import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll'

import 'alpinejs'
import 'tailwindcss/tailwind.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

$(document).on('turbolinks:load', function () {
  new SweetScroll()

  $('select.select2').select2({
    placeholder: "Any"
  })

  $('select.select3').select2({
    placeholder: "Any"
  })

})

$(document).on('turbolinks:before-cache', function () {
  $('select.select2').select2('destroy')
})
