import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'text']

  click () {
    this.inputTarget.click()
  }

  show () {
    this.textTarget.innerText = this.inputTarget.files[0].name
  }
}
